/**
 * this component will handle the media asset coming from amplience.
 *
 * @module views/components/GamBanner
 * @memberof -Common
 */
import './GamBanner.scss';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import {
  GOOGLE_ADS_PUBLISHER_ID,
  GOOGLE_ADS_RESPONSIVE_MAPPING,
  GOOGLE_ADS_SIZES,
  GOOGLE_ADS_WEB_KEY,
  UB_MEDIA_AD_CLASS_NAMES,
  UB_MEDIA_DXL_TO_GAM_KEYS
} from '@ulta/utils/constants/ubMedia';

/**
 * Represents a GamBanner component
 *
 * @method
 * @param {GamBannerProps} props - React properties passed from composition
 * @returns GamBanner
 */
export const GamBanner = function( props ){
  const {
    accessibilityLabel,
    adUnitType,
    enableSRA,
    keyWords,
    onDisplayed,
    pageType,
    position,
    setShowSpacer,
    rootMargin,
    root,
    sponsoredLabel,
    threshold
  } = props;

  const [tagLoaded, setTagLoaded] = useState( false );
  const [show, setShow] = useState( false );
  const slot = useRef();
  const wrapperRef = useRef();
  const timerRef = useRef();
  const { sizes, responsiveMapping, className, adName, gamId } = propAdapter( {
    adUnitType,
    pageType,
    position
  } );
  // Start Datacapture Impression
  useIntersectionObserver(
    wrapperRef,
    {
      root: root,
      rootMargin: rootMargin,
      threshold: threshold
    },
    handleIntersection( props )
  );
  // End Datacapture Impression

  // Refs that handle loading state and div wrapper
  const adLoaded = useRef( false );
  const divIdRef = useRef( getDivId( { adName } ) );
  const divId = divIdRef.current;

  // Resize listener ref
  const resizeTimer = useRef();

  // Previous screen width ref
  const adBreakPointKey = useRef();

  // Define slot, start loading google tag on mount, and listen for resize events
  useEffect( () => {
    const initialScreenWidth = getKeyFromSize( { responsiveMapping } ) ;
    adBreakPointKey.current = initialScreenWidth;
    loadGoogleTag( { timerRef }, { setTagLoaded } );
    defineAdSlot( { gamId, sizes, slot, responsiveMapping, divId, keyWords, enableSRA }, { setShow } );
    const resizeCallback = composeResizeListener( { resizeTimer, responsiveMapping, slot, adBreakPointKey } );
    global.addEventListener( 'resize', resizeCallback );

    return () => {
      destroyAdSlot( { slot } );
      global.clearInterval( timerRef.current );
      global.removeEventListener( 'resize', resizeCallback );
    };
  }, [] );


  // Displays ad after google tag is done loading
  useEffect( () => {
    if( !tagLoaded || !wrapperRef.current || adLoaded.current ){
      return;
    }
    setTimeout( ()=>{
      adLoaded.current = true;
      displayGoogleAd( { divId } );
    }, AD_DISPLAY_DELAY );
  }, [tagLoaded, wrapperRef] );

  // collapse spacer component if we will not receive Banner from GAM.
  useEffect( () => {
    if( !tagLoaded ){
      return;
    }

    if( setShowSpacer ){
      setShowSpacer( show );
    }

    if( onDisplayed ){
      onDisplayed( show );
    }
  }, [show, tagLoaded, setShowSpacer, onDisplayed] );

  if( !tagLoaded ){
    return null;
  }
  const hasAccessibilityLabel =  ( accessibilityLabel?.length !== 0 && show );
  return (
    <div className='GamBanner'
      ref={ wrapperRef }
    >
      <div className={ classNames( 'GamBanner__wrapper', className ) }>
        { hasAccessibilityLabel && <p className='sr-only'>{ accessibilityLabel }</p> }
        <div
          className={ classNames( 'GamBanner__slot', { 'GamBanner__slot--show': show } ) }
          id={ divId }
        >
        </div>
        { show && <div className='GamBanner__sponsoredText'>{ sponsoredLabel }</div> }
      </div>
    </div>
  );
};

/**
 * Composes a destroyAdSlot helper to destroy defined slot if overlay current have slot
 *
 * @param {object} data args
 * @param {object} data.overlayAd React ref to store slot if overlay ad will display
 */
export const destroyAdSlot = ( data ) => {
  const { slot } = data || {};

  if( !slot?.current ){
    return;
  }
  global.googletag.destroySlots( [slot.current] );

};

/**
 * Composes a resize listener that will set a new ad key on resize to refresh the ad
 *
 * @param {object} data args
 * @param {object} data.resizeTimer React ref to manage timer assignment
 * @param {object} data.adBreakPointKey React ref to manage current width key
 * @param {object} data.slot React ref to manage slot
 * @param {array} data.responsiveMapping List of screen size to ad size mappings for ad
 */
export const composeResizeListener = ( data ) => {
  const { resizeTimer, adBreakPointKey, slot, responsiveMapping } = data || {};

  return () => {
    clearTimeout( resizeTimer.current );
    resizeTimer.current = setTimeout( () => {
      const key = getKeyFromSize( { responsiveMapping } );
      if( key !== adBreakPointKey.current && slot.current ){
        global.googletag.pubads().refresh( [slot.current] );
        adBreakPointKey.current = key;
      }
    }, 50 );
  };
};

/**
 * TODO: Write tests for this function
 * - should return the width for the responsive entry
 * - write one test for the error case where there isn't a mapping entry defined
 *   - clientWidth = 200 and there is only one entry of [300, 300] for example
 * - write a test for if the responsive mapping is not an array, or an array of length 0
 *
 * Returns a width integer that is used as the ad key
 *
 * @param {object} data args
 * @returns {number} current responsive mapping's width
 */
export const getKeyFromSize = ( data ) => {
  const { responsiveMapping } = data || {};

  if( !Array.isArray( responsiveMapping ) || responsiveMapping.length === 0 ){
    return 0;
  }

  const { clientWidth } = global.document.documentElement;
  const adBreakPointEntry = responsiveMapping.find( ( entry ) => clientWidth >= entry.viewport[0] );

  return adBreakPointEntry?.viewport?.[0] || 0;
};

/**
 * TODO: Write tests for this function
 * 1. if adUnitType, pageType, and position are empty
 * 2. if we don't have keys for sizes, responsiveMapping, or className
 * 3. sets propsValid to false
 *
 * - Unit tests should pass in bad data for adUnitType, pageType, and position
 * - Unit tests should not fail when no args are passed
 * - propsValid should only be true if all adapted props are passed in
 *
 * Ensures we have the props we need
 *
 * @param {object} data args
 * @param {object} data.adUnitType DXL ad key
 * @param {object} data.pageType DXL page type
 * @param {object} data.position DXL position
 * @returns {object} props
 */
export const propAdapter = ( data ) => {
  const { adUnitType, pageType, position } = data || {};

  const device = GOOGLE_ADS_WEB_KEY;
  const adUnitKey = adUnitType?.replace( /\s/g, '_' ).toUpperCase();
  const gamAdUnitKey = UB_MEDIA_DXL_TO_GAM_KEYS[adUnitKey];

  const sizes = GOOGLE_ADS_SIZES[adUnitKey];
  const responsiveMapping = GOOGLE_ADS_RESPONSIVE_MAPPING[adUnitKey];
  const className = UB_MEDIA_AD_CLASS_NAMES[adUnitKey];
  const adName = `${pageType}_${device}_${gamAdUnitKey}_${position}`;
  const gamId = `${GOOGLE_ADS_PUBLISHER_ID}/${adName}`;

  // Gather list of props we want for the component
  const adapted = { sizes, responsiveMapping, className, gamId, adName };

  // Define if prop check passes. For this component we just need
  // to check if props keys exist in out constants, but we could do
  // any kind of validation here
  const propsValid = Object.keys( adapted ).reduce( ( valid, prop ) => valid && !!prop, true );

  // Pass back component's sanitized props along with `propsValid` flag
  return { ...adapted, propsValid };
};

/**
 * Tells GPT to display our ad
 * @param {object} data args
 * @param {object} data.divId Id of the ad to display
 */
export const displayGoogleAd = function( data ){
  const { divId } = data || {};

  if( !divId || !global.googletag ){
    return;
  }

  global.googletag.cmd?.push( function(){
    global.googletag.display( divId );
  } );
};

/**
 * Object that will store records of ads displayed on the page
 * This is used to ensure we have a unique div id per ad, in case "PositionB" is selected
 * twice on a page we still need to generate a unique div/ad id.
 * [adName]: # of renders
 *
 * @constant {object} GOOGLE_ADS_RENDERED
 */
export const GOOGLE_ADS_RENDERED = {};

/**
 * @constant {object} GOOGLE_ADS_SHARED_STATE Global key to manage state
 */
export const GLOBAL_ULTA_UB_MEDIA_KEY = '__ULTA_UB_MEDIA__';

/**
 * @const {number} AD_DISPLAY_DELAY To add delay to call display method
 */
export const AD_DISPLAY_DELAY = 50;

/**
 * @const {number} TAG_LOAD_INTERVAL To set Interval check to googletag loaded
 */
export const TAG_LOAD_INTERVAL = 50;

/**
 * Guarantees a unique ID based on passed in string
 *
 * @param {objects} data args
 * @param {objects} data.id the ad name
 * @returns {string} unique id
 */
export const getDivId = ( data ) => {
  const { adName } = data || {};
  if( !adName ){
    return '';
  }
  GOOGLE_ADS_RENDERED[adName] = GOOGLE_ADS_RENDERED[adName] ? GOOGLE_ADS_RENDERED[adName] + 1 : 1;

  return ( `${adName}-${GOOGLE_ADS_RENDERED[adName]}` ).replace( /\s/i, '-' );
};

/**
 * Helper methods to define and ad slot
 * @param {object} data args
 * @param {string} data.gamId GAM id for the ad
 * @param {object} data.slot slot ref
 * @param {array} data.sizes array of sizes the ad will support
 * @param {string} data.divId ad name/id, must match here, on the div, and when we call display
 * @param {object} methods methods
 * @param {function} methods.setShow show setter
 */
export const defineAdSlot = ( data, methods ) => {
  const { gamId, slot, sizes, responsiveMapping = [], divId, keyWords = [], enableSRA } = data || {};
  const { setShow } = methods || {};

  if( !gamId || !Array.isArray( sizes ) || sizes?.length === 0 || !divId ){
    return;
  }

  // Define global state object for UB Media related state
  global[GLOBAL_ULTA_UB_MEDIA_KEY] = global[GLOBAL_ULTA_UB_MEDIA_KEY] || {};

  // Queue google publisher tag
  global.googletag = global.googletag || {};
  global.googletag.cmd = global.googletag.cmd || [];

  // Push slot definition and event listener
  global.googletag.cmd.push( function(){
    const gamSlot = global.googletag.defineSlot( gamId, sizes, divId );

    gamSlot.addService( global.googletag.pubads() );
    // Listen for render event and set show to true
    global.googletag.pubads()?.addEventListener( 'slotRenderEnded', ( event ) => {
      if( event?.isEmpty || event?.slot !== gamSlot ){
        return;
      }
      slot.current = gamSlot;
      setShow( true );
    } );

    // Build responsive mapping
    if( Array.isArray( responsiveMapping ) && responsiveMapping.length > 0 ){
      const mapBuilder = global.googletag.sizeMapping();
      responsiveMapping.forEach( ( entry ) => {
        mapBuilder.addSize( entry.viewport, entry.sizes );
      } );

      const mapping = mapBuilder?.build();

      gamSlot?.defineSizeMapping( mapping );
    }

    // If we've already ran our initialization and key value targeting we can bail
    if( global[GLOBAL_ULTA_UB_MEDIA_KEY].init === true ){
      return;
    }

    // Set a global flag so we only enable services and define page level targeting one time
    global[GLOBAL_ULTA_UB_MEDIA_KEY].init = true;

    // ConfigureGamBannerKeyValue
    global.googletag.pubads()?.collapseEmptyDivs();

    // Targeting
    if( Array.isArray( keyWords ) && keyWords.length > 0 ){
      keyWords.forEach( ( { key, values } ) => {
        global.googletag.pubads()?.setTargeting( key, values );
      } );
    }

    // To enable Single Request Architecture (SRA)
    if( enableSRA ){
      global.googletag.pubads()?.enableSingleRequest();
    }

    // Enable services
    global.googletag.enableServices();
  } );
};

/**
 * Lets our ad component know if Google has been loaded
 *
 * @param {object} data args
 * @param {object} data.timerRef stable ref for set interval
 * @param {object} methods functions
 * @param {object} method.setTagLoaded lets the component know google tag has been loaded
 */
export const loadGoogleTag = ( data, methods ) => {
  const { timerRef } = data || {};
  const { setTagLoaded } = methods || {};
  if( !timerRef || !setTagLoaded ){
    return;
  }

  if( global?.googletag ){
    setTagLoaded( global.googletag );
    return;
  }

  timerRef.current = setInterval( () => {
    if( global?.googletag ){
      clearInterval( timerRef.current );
      setTagLoaded( global.googletag );
    }
  }, TAG_LOAD_INTERVAL );
};

/**
 * Property type definitions
 * @typedef GamBannerProps
 * @type {object}
 * @property {string} adUnitType - Sets the unit type
 * @property {string} accessibilityLabel - Sets the accessibility Label
 * @property {boolean} enableSRA - Enables Single Request Architecture
 * @property {array} keyWords - Sets the array of product categories
 * @property {string} pageType - Sets the page type
 * @property {string} position - Sets the position of ad slot
 *  @property {string} spacerValue - Sets the spacer below the component
 */
export const propTypes = {
  /** Set the unit type */
  adUnitType: PropTypes.string,
  /** Set the page type */
  accessibilityLabel: PropTypes.string,
  /** Set the page type */
  enableSRA: PropTypes.bool,
  /** Set the array of product categories */
  keyWords: PropTypes.arrayOf(
    PropTypes.shape( {
      key: PropTypes.string,
      values: PropTypes.array
    } )
  ),
  /** Set the page type */
  pageType: PropTypes.string,
  /** Set the position of ad slot */
  position: PropTypes.string
};

GamBanner.propTypes = propTypes;
GamBanner.defaultProps = {
  enableSpacer: false
};

export default GamBanner;
