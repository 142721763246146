
/**
 * @const {string} GOOGLE_ADS_PUBLISHER_ID GAM account ID
 */
export const GOOGLE_ADS_PUBLISHER_ID = process.env.GOOGLE_ADS_PUBLISHER_ID || '22764876099' ;

/**
 * @const {string} GOOGLE_ADS_WEB_KEY Static client key for ad slot string
 */
export const GOOGLE_ADS_WEB_KEY = 'Web';

/**
 * @const {string} UB_MEDIA_DXL_TO_GAM_KEYS Maps DXL ad unit type to the string used for ad slot in GAM
 */
export const UB_MEDIA_DXL_TO_GAM_KEYS = {
  BILLBOARD: 'Billboard',
  SUPER_LEADERBOARD: 'Leaderboard',
  CUSTOM_LEADERBOARD: 'Customleaderboard',
  MEDIUM_RECTANGLE: 'MediumRectangle'
};

/**
 * @const {string} GOOGLE_ADS_SIZES Maps ad unit sizes in GAM
 */
export const GOOGLE_ADS_SIZES = {
  BILLBOARD: [[970, 250], [300, 250]],
  SUPER_LEADERBOARD: [[970, 90], [768, 90], [320, 50]],
  CUSTOM_LEADERBOARD: [[768, 90], [320, 50]],
  MEDIUM_RECTANGLE: [[300, 250]]
};

/**
 * These keys must be sorted by largest resolution first
 * @const {string} GOOGLE_ADS_RESPONSIVE_MAPPING Maps window size to ad size
 */
export const GOOGLE_ADS_RESPONSIVE_MAPPING = {
  BILLBOARD: [
    { viewport: [1024, 480], sizes: [970, 250] },
    { viewport: [800, 480], sizes: [768, 250] },
    { viewport: [340, 100], sizes: [300, 250] },
    { viewport: [0, 0], sizes: [] }
  ],
  SUPER_LEADERBOARD: [
    { viewport: [1024, 480], sizes: [970, 90] },
    { viewport: [800, 480], sizes: [768, 90] },
    { viewport: [340, 100], sizes: [320, 50] },
    { viewport: [0, 0], sizes: [] }
  ],
  CUSTOM_LEADERBOARD: [
    { viewport: [1440, 480], sizes: [768, 90] },
    { viewport: [1024, 480], sizes: [320, 50] },
    { viewport: [800, 480], sizes: [320, 50] },
    { viewport: [340, 100], sizes: [320, 50] },
    { viewport: [0, 0], sizes: [] }
  ],
  MEDIUM_RECTANGLE: [
    { viewport: [340, 250], sizes: [300, 250] },
    { viewport: [0, 0], sizes: [] }
  ]
};

/**
 * @const {string} UB_MEDIA_AD_CLASS_NAMES Maps ad type to class name
 */
export const UB_MEDIA_AD_CLASS_NAMES = {
  BILLBOARD: 'UBMedia__billBoard',
  SUPER_LEADERBOARD: 'UBMedia__leaderBoard',
  CUSTOM_LEADERBOARD: 'UBMedia__customleaderBoard',
  MEDIUM_RECTANGLE: 'UBMedia__mediumRectangle'
};
